<template>
    <v-expansion-panel>
        <template>
            <v-expansion-panel-header class="mt-2">
                  Buscar partida contable
  
                <template v-slot:actions>
                    <v-btn class="mr-5" @click.native.stop="reload()" icon small>
                        <v-icon color="primary">mdi-reload</v-icon>
                    </v-btn>
                    <v-btn @click="$store.dispatch('deleteWindowByUuid',{uuid : win.uuid})" icon small>
                        <v-icon color="error">mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>
                    <v-tab href="#tab-1">
                      Buscar partida contable
                        <v-icon>mdi-bank</v-icon>
                    </v-tab>
                </v-tabs>
  
                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item value="tab-1">
                        <v-card class="pa-1">
                            <v-card-text class="white" style="border-radius: 10px;">
                                      <AcountItemHistory  :win="win" :key="key"    />               
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-expansion-panel-content>
        </template>
        
    </v-expansion-panel>
  </template>
  
  <script>
    
    //import {mapState} from 'vuex';
    import AcountItemHistory from "../partial/AcountItemHistory.vue";

  
    export default {
        name: "AcountingSearch",
        components: {
            AcountItemHistory,
        },
        props: ['win'],
        data() {
            return {
                panel: 0,
                tab: null,
                key: 0,
                show: false,
                TypesItems: [
                    { name: "Debe", value: "Debe" },
                    { name: "Haber", value: "Haber" },
                ],
                
            }
        },
        mounted() {
            this.getAcounteringType();
        },
        computed:{
            /*...mapState('customerData', {
                tab: 'tab-1'
            }),*/
        },
        methods: {
            getAcounteringType() {
            this.$API.branchaccountcatalog
                .getAcounteringType()
                .then((res) => {
                    this.TypesItems = res.data;
                     this.show = true;
                })
                .catch((err) => {
                    console.log(err);
                    this.show = true;
                });
            },
            reload() {
                this.key++;
                setTimeout(() => {
                window.scrollTo(0, this.$store.getters.getScrollTop)
                //alert(this.$store.getters.getScrollTop)
                },300);
            },
        },
  
    }
  </script>
  
  <style scoped>
  
  </style>